'use strict'

###*
 # @ngdoc service
 # @name mundoData.factory:AssetDataData

 # @description

###
angular
  .module 'mundoData'
  .factory 'AssetDataData', [
    'Restangular'
    (Restangular) ->
      AssetDataDataBase = Restangular
        .service('assets/data')

      AssetDataDataBase
  ]
